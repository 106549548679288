<template>
  <div id="report-integratedManagement" class="report">
    <div class="processingRate-timeliness">
      <div class="processingRate statisticalAnalysis">
        <div class="title-filter">
          <h1>运维处理率</h1>
        </div>
        <processingRate class="chartDetail"/>
      </div>
      <div class="timeliness statisticalAnalysis">
        <div class="title-filter">
          <h1>运维服务时效性分析</h1>
        </div>
        <timeliness class="chartDetail" />
      </div>
    </div>
    <div class="faultType-failureRate">
      <div class="faultType statisticalAnalysis">
        <div class="title-filter">
          <h1>设备类故障类型分析</h1>
        </div>
        <faultType class="chartDetail" />
      </div>
      <div class="failureRate statisticalAnalysis">
        <div class="title-filter">
          <h1>元器件故障率分析</h1>
        </div>
        <failureRate class="chartDetail" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    processingRate: () => import("./processingRate"),
    timeliness: () => import("./timeliness"),
    faultType: () => import("./faultType"),
    failureRate: () => import("./failureRate"),
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
#report-integratedManagement {
  .processingRate-timeliness {
    display: flex;
    justify-content: space-between;
    height: 20rem;

    .processingRate {
      width: 30%;
    }

    .timeliness {
      width: calc(70% - 1.5rem);
    }
  }
  .faultType-failureRate{
    display: flex;
    justify-content: space-between;
    height: 20rem;
    margin-top: 1.5rem;
    .faultType {
      width: calc(70% - 1.5rem);
    }

    .failureRate {
      width: 30%;
    }
  }
}
</style>
